:root {
  --letter-width:0%;
  --cursor-drag:initial;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');
@import 'media.scss';
@font-face {
  font-family: "Agipo mono";
  // src: url("CustomFont.eot");
  src: url("./fonts/AgipoMono-Regular.woff") format("woff"),
  }
@font-face {
  font-family: "Agipo";
  font-weight: 400;
  // src: url("CustomFont.eot");
  src: url("./fonts/AgipoLight-Regular.woff") format("woff"),
  }
@font-face {
  font-family: "Agipo";
  font-weight: 400;
  font-style: italic;
  // src: url("CustomFont.eot");
  src: url("./fonts/AgipoLight-Italic.woff") format("woff"),
  }


html, body {
  font-size: 62.5%;
  font-family: "Agipo";
  font-weight: 400;
  background-color: #FAFAFA;
  
  // font-weight:normal;
  cursor: var(--cursor-drag);
  @include mobile {
    font-size: 50%;
    // max-height: 100vh;
    // overflow-y: hidden;
  }
  user-select: none;
  
}
html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}

p {
  margin: 0;
}
h1 {
  letter-spacing: -1px;
}
#root {
  #lettersContainer {
    display: flex;
    flex-direction: row;
    width: 95vw;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
    
  }
  height: 100%;
  overflow: hidden;
  span {
    display: inline-block;
  }

  #subtitles {
    position: fixed;
    bottom: 0rem;
    left: 50%;
    transform: translate(-50%, 0%);
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height:100vh;
    width: 100%;
    justify-content: space-between;
    // transform: translate(-50%, -50%);
    // width: auto;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    p {
      font-size: 2rem;
      // position: fixed;
      // bottom: 4rem;
    }
    & > div {
      position: absolute;
      width: 100%;
      // height:4rem;
      border-top: 2px solid black;
      font-size: 2rem;
      display:flex;
      align-items: center;
      transition: height 0.1s linear;
      overflow: hidden;
      span {
        // position: relative;
        // width: 100vw;
        // left:100%;

       }



    }
  }
}
.tickerContainer {
  // font-family: 'Inter', sans-serif;
  font-family: 'Agipo mono', sans-serif;
  background-color: white;
  position: fixed;
  width: 100%;
  height:5rem;
  left:0;
  border-top: 2px solid black;
  font-size: 2rem;
  display:flex;
  align-items: center;
  transition: height 0.1s linear, bottom 0.1s linear;
  overflow: hidden;
  .ticker {
    width:100%;

    .ticker__element {
      height:2.5rem;
      white-space: nowrap;
    }
  }
}



.letter, .space {
  display: inline-block;
  position: relative;
  height: 100vh;
  width: var(--letter-width);
  // font-family: 'Agipo', sans-serif;
  text-transform: uppercase;
  // font-family: 'Inter', sans-serif;
  // font-weight: lighter;
  
  & > p:not(.textDescription) {
    cursor:grabbing;
    font-size: 7rem;
    text-align: center;
    position: absolute;
    // top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    // text-shadow:0px 0 2px rgba(255, 255, 255, 0.459);
    // z-index: 100;
    &.hasContent {
      // text-shadow:0px 0 4px rgba(255, 0, 0, 0.459);
      
    }
    @include mobile {
      font-size: 4rem;
    }
    // cursor: grab;
  }
  .tickerContent p {
    font-size: 2rem;
    position: absolute;
  }
  .fold {
    // transition: clip-path 0.1s linear;
    z-index: 0;
    position: fixed;
    top:50%;
    left:20%;
    &:nth-child(2) {
      top:20%;
      left:50%;

    }
    // transition: clip-path 1.5s cubic-bezier(0.16, 0.56, 0.42, 0.91);
    img {
      // transition: clip-path 1.5s cubic-bezier(0.16, 0.56, 0.42, 0.91);
      display: block;
      width: 400px;
      pointer-events: none;
      user-select: none;
    }
  
    h1 {
      position: fixed;
      top:20px;
      left: 20px;
    }
  }
  // top: 0;
}
.space {
  width: calc(var(--letter-width) - 2rem);
}
// .space p, .letter p {
// }
.transition > *, .transition img, .transition video {
  transition: all 2.5s cubic-bezier(0.16, 0.56, 0.42, 0.91);

}
.contentContainer {
  // position: relative;
  // width: 100vw;
  // height: 100vh;
  // display: block;
  // z-index: 0;
  .textDescription {
    pointer-events: none;
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 200;
    font-size: 2rem;

  }

.textDescription {
  pointer-events: none;
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 200;
  font-size: 2rem;

}

}
