$tablet-width: 768px;
$desktop-width: 1024px;

$mobile-min: 100px;
$mobile-max: 812px;

$phone-width: 200px;


@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop_3_col {
  @media (max-width: 1200px) {
    @content;
  }
}


@mixin desktop_2_col {
  @media (max-width: 900px) {
    @content;
  }
}

// @mixin mobile {
// 	@media (min-width: #{$mobile-min}) and (max-width: #{$mobile-max}) {
// 		@content;
// 	}
// }

@mixin mobile {
	@media (min-device-width: #{$mobile-min}) and (max-device-width: #{$mobile-max}) {
		@content;
	}
	@media (min-width: #{$mobile-min}) and (max-width: #{$mobile-max}) {
		@content;
	}
}

@mixin desktop_small {

	@media (min-width: #{$mobile-min}) and (max-width: #{$mobile-max}) {
		@content;
	}
}

@mixin device {
	@media (min-device-width: #{$mobile-min}) and (max-device-width: #{$mobile-max}) {
		@content;
	}
}

@mixin desktop_wide {
  @media (min-width: 1880px) {
    @content;
  }

}